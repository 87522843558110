import React from 'react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { Container, Typography } from '@material-ui/core'
import Link from '@components/Link'
// import Bio from '@components/bio'
import Layout from '@components/layout'
import SEO from '@components/seo'
import Comments from '@components/comments'
import { rhythm } from '@utils/typography'
// import GridContainer from '@components/Grid/GridContainer'
// import GridItem from '@components/Grid/GridItem'
import clsx from 'clsx'
import styles from '@assets/jss/views/components.js'
import { makeStyles } from '@material-ui/core/styles'
import Parallax from '@components/Parallax/Parallax'
// import Card from "@components/Card/Card.js"
// import CardBody from "@components/Card/CardBody.js"
// import CardHeader from "@components/Card/CardHeader.js"
import Grid from '@material-ui/core/Grid'
import Image from 'gatsby-image'

const useStyles = makeStyles({
  ...styles,
  wrap: {
    width: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  title: {
    ...styles.container,
    paddingTop: '20px',
    paddingBottom: '30px',
    color: '#ffeedb',
    textAlign: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    position: 'absolute',
    zIndex: '50'
  }
})

const BlogPostTemplate = (props) => {
  const classes = useStyles()
  const post = props.data.mdx
  const siteTitle = props.data.site.siteMetadata.title
  const { previous, next } = props.pageContext
  // const FeaturedImage = () => { 
  //   console.log(props.data)
  //   if (props.data.mdx.frontmatter.featuredImage) {
  //     return <Image fluid={props.data.mdx.frontmatter.featuredImage.childImageSharp.fluid} />
  //   }
  //   return <></>
  // }
  const parallax = post.frontmatter.featuredImage
  ? {
      filter: true,
      image: '',
      style: {},
      children: (
        <div className={classes.wrap}>
          <div style={{ width: "100%", zIndex: '-10' }}>
            <Image
              fluid={post.frontmatter.featuredImage.childImageSharp.fluid}
            />
          </div>
          <div className={classes.title}>
            <Typography
              variant="h4"
              component="h3"
              style={{
                marginTop: 8,
              }}
            >
              {post.frontmatter.title}
            </Typography>
          </div>
        </div>
      ),
    }
  : {
      filter: true,
      image: require('@assets/img/bg0.jpg'),
      style: { height: '480px' },
      children: (
        <div className={classes.wrap}>
          <div className={classes.title}>
            <Typography
              variant="h4"
              component="h3"
              style={{
                marginTop: 8,
              }}
            >
              {post.frontmatter.title}
            </Typography>
          </div>
        </div>
      ),
    }
  return (
    <Layout location={props.location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <Parallax
        {...parallax}
      >
      </Parallax>
      <div className={clsx(classes.main, classes.mainRaised)}  style={{ marginTop: "-80px", backgroundColor: "rgb(255,252,255)" }}>
        <div className={classes.container}>
          <br />
          <Typography component="p">
            {post.frontmatter.date}
          </Typography>
          <hr
            style={{
              marginBottom: rhythm(1),
            }}
          />
          <MDXRenderer>{post.body}</MDXRenderer>
          <hr />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              {previous && (
              <>
                前一条: &nbsp;&nbsp;
                <Link to={`/${previous.frontmatter.type}${previous.fields.slug}`} rel="prev">
                  {previous.frontmatter.title}
                </Link>
              </>
              )}
            </Grid>
            <Grid item xs={6} style={{ display: 'flex', justifyContent: "flex-end" }}>
              {next && (
              <>
                下一条: &nbsp;&nbsp;
                <Link to={`/${next.frontmatter.type}${next.fields.slug}`} rel="next">
                  {next.frontmatter.title}
                </Link>
              </>
              )}
            </Grid>
          </Grid>          
          <br /><br />
          <Container>
            <Comments />
          </Container>
          <br /><br />
        </div>
      </div>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        featuredImage {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
